import React from 'react';
import Navbar from './Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './style.css';
import './css/main.css';
import './mobile.css'
import Home from './Home';
import Footer from './Footer';
import Product from './Product/Product';
import Cart from './cart/Cart';
import ProductSample from './Product/ProductSample';
import Signup from './Signup';
import Login from './Login';
import Category from './Category';

const Main = () => {
  return (
    <div>
        <Router>
            <Navbar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/login' element={<Login />} />
                <Route path='/product/:id' element={<Product />} />
                <Route path='/productSample' element={<ProductSample />} />
                <Route path='/product/category/:name' element={<Category />} />
                <Route path='/bag' element={<Cart />} />
            </Routes>

            <Footer />
        </Router>
    </div>
  )
}

export default Main