import React from 'react'

const Footer = () => {
return (
<div id='footer'>
    <div className="container">
        <div className="footer">
            <div className="row">
                <div className="col-lg-4 col-sm-4 col-xs-12">
                    <div className="single_footer">
                        <h4>Shop For</h4>
                        <ul>
                            <li><a href="#">Salwar Kameez</a></li>
                            <li><a href="#">Sarees</a></li>
                            <li><a href="#">Kurtis</a></li>
                            <li><a href="#">Lehengas</a></li>
                            <li><a href="#">Gowns</a></li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="single_footer single_footer_address">
                        <h4>About</h4>
                        <ul>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Refund Policy</a></li>
                            <li><a href="#">Terms & Conditions</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="single_footer single_footer_address">
                        <h4>Get In Touch</h4>
                        {/* <p className='m-0'>90/30 B, Ground Floor<br />
                            Main Market, Malviya Nagar</p> */}
                    </div>
                    <div className="social_profile">
                        <ul>
                            <li><a href="#"><i className="bx bxl-facebook"></i></a></li>
                            <li><a href="#"><i className="bx bxl-instagram"></i></a></li>
                            <li><a href="#"><i className="bx bxl-twitter"></i></a></li>
                            <li><a href="#"><i className="bx bxl-linkedin"></i></a></li>
                        </ul>
                    </div>                          
                </div>
            </div>

            <p className="copyright text-center">© 2023 Tokree</p>

        </div>
    </div>
</div>
)
}

export default Footer