import React from 'react'
import img1 from '../../media/suit.avif'
import img2 from '../../media/img1.webp'
import GoToTop from '../js/GoToTop'

const Items = (props) => {
  return (
      <div className='item-page'>
			<div id="item" className="my-4 p-2">
				<p className="lead">You have 2 items in your bag.</p>

				<div className="my-3">
					<div className="row align-items-center my-4">
						<div className="col-lg-2 text-lg-end">
							<img src={img1} alt="" width="40" />
						</div>
						<div className="col-lg-7">
							<h4>Banarasi Silk Suit with Dupatta</h4>
							<div className="star">
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} /> 
                                32 reviews
                            </div>
							Style No 111 <br />
                            Estimated Delivery Date: 13 Dec 2022 (India) + 3 Business Days (International)
                            <br />
                            <h5>₹2400</h5>
						</div>
						<div className="col-lg-3 my-sm-4 text-sm-center">
							<div className="no-items d-block my-3">
								<button className='dec'>-</button>
								<button className='inc'>+</button>
							</div>
							<button className='btn btn-solid'>Delete from bag</button>
						</div>
					</div>
					<div className="row align-items-center my-4">
						<div className="col-lg-2 text-lg-end">
							<img src={img2} alt="" width="40%" />
						</div>
						<div className="col-lg-7">
							<h4>Banarasi Silk Saree with Unstitched Blouse</h4>
							<div className="star">
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} /> 
                                34 reviews
                            </div>
							Style No 112 <br />
                            Estimated Delivery Date: 13 Dec 2022 (India) + 3 Business Days (International)
                            <br />
                            <h5>₹2400</h5>
						</div>
						<div className="col-lg-3 my-sm-4 text-sm-center">
							<button className='btn btn-solid'>Delete from bag</button>
						</div>
					</div>
				</div>
				<div className="text-center">
					<button className='btn btn-solid mt-2' onClick={props.nextStep}>Proceed with 2 items.</button>
				</div>
			</div>
			<GoToTop />
		</div>
  )
}

export default Items