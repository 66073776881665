import img1 from '../../media/img1.webp';
import img2 from '../../media/suit.avif';

export const ImgData = [
    {
        img: img1,
        link: '#',
        productDesc: 'Silk Saree',
        price: 2400
    },
    {
        img: img2,
        link: '#',
        productDesc: 'Banarasi Saree',
        price: 2600
    },
    {
        img: img1,
        link: '#',
        productDesc: 'Another Saree',
        price: 1400
    },
    {
        img: img2,
        link: '#',
        productDesc: 'Simple Saree',
        price: 2400
    },
    {
        img: img1,
        link: '#',
        productDesc: 'Gold Saree',
        price: 2000
    },
    {
        img: img1,
        link: '#',
        productDesc: 'Silver Saree',
        price: 2400
    }
]