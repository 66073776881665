import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import img1 from '../../media/img1.webp';
import { addCart, addWishList, fetchProductById } from '../../actions/products';
import GoToTop from '../js/GoToTop';

const Product = () => {

    const user = JSON.parse(localStorage.getItem('profile'))

	const {product, isLoading} = useSelector((state) => state.product);
    const [selectedSize, setSelectedSize] = useState(null)
    const id = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchProductById(id));
    }, [])

    const addWishListHandler = () => {
        if(user) {
            const item = { id: product.id, name: product.productName, size: selectedSize }
            dispatch(addWishList(user.id, item))
        } else {
            navigate('/login')
        }
    }
    const addCartHandler = () => {
        const item = { id: product.id, name: product.productName, size: selectedSize }
        dispatch(addCart(user.id, item))
    }

    
    return (
        <div className='product-page mt-4 container'>
            { !product ? (<h4>Loading...</h4>) : 
                (<div id='product' className=' my-5'>
        
                    <div className="row align-items-start">
                        <div className="col-lg-2">
                            <img src={img1} alt="" width={120} className='mb-2' />
                            <img src={img1} alt="" width={120} className='mb-2' />
                            <img src={img1} alt="" width={120} className='mb-2' />
                        </div>
                        <div className="col-lg-5">
                            <img src={img1} alt="" />
                        </div>
                        <div className="col-lg-5">
                            <h4>{`${product.productDesc}, ${product.sleeves}, ${product.color} `}</h4>
                            <div className="star">
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                                <i className='bx bxs-star' style={{color: '#c79d6f'}} /> 
                                34 reviews
                            </div>
                            <br />
                            Style No {product.styleNo} <br />
                            Estimated Delivery Date: 13 Dec 2022 (India) + 3 Business Days (International)
                            <br /> <br />
                            <h3>₹{product.price}</h3>
        
                            <div className="size-chart">
                                <button className='btn'>Size chart</button>
                            </div>
        
                            <div className="sizes mt-4">
                                <span>Select Size</span>
                                <div>
                                    <ul>
                                        {product.sizeAvailableList.map((item, index) => (
                                            <li key={index} onClick={() => {setSelectedSize(item)}} >US {item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div>
                                <button className="btn btn-solid" onClick={addWishListHandler}>
                                    Add to WishList
                                </button>
                                <button className="btn btn-solid" onClick={addCartHandler}>
                                    Add to Cart
                                </button>
                            </div>
                            
        
                        </div>
                    </div>
                </div>)
            }
            <GoToTop />
        </div>
    )
}

export default Product