import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from '../media/carousel.avif';
import img2 from '../media/carousel2.avif';
import img3 from '../media/carousel3.avif';
import img4 from '../media/carousel4.avif';
// import img2 from '../media/carousel.jpg';
import img5 from '../media/img1.webp';
import { ImgData } from './data/ImgData';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProduct } from '../actions/products';
import GoToTop from './js/GoToTop'

const Home = () => {

	const {products, isLoading} = useSelector((state) => state.product);
	const dispatch = useDispatch();


	useEffect(() => {
		dispatch(fetchAllProduct());
	}, [])



	
	const [options2, setoptions2] = useState({
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

	const [options, setoptions] = useState({
        loop: true,
        margin: 10,
        nav: true,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

	const [freeShipingModal, setFreeShipingModal] = useState(false);
	const [returnModal, setReturnModal] = useState(false);
	const [styledModal, setStyledModal] = useState(false);
	const [countriesModal, setCountriesModal] = useState(false);
	const [fittingModal, setFittingModal] = useState(false);

	const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '40px',
          borderRadius: '20px',
		  minHeight: '20vh',
		  width: '50vw',
        },
    };

  return (
    <div id='home'>
		<div className='bottom-bar'>
			<OwlCarousel {...options2}>
				<div>
					<p>New Users Get 15% OFF</p>
				</div>				
				<div>
					<p>Shop for Rs 20000, and get 20% OFF</p>
				</div>				
			</OwlCarousel>
		</div>

        <section id='carousel' className='container carousel p-0 mt-4'>
			<OwlCarousel {...options}>
				<div>
					<img src={img1} alt="" />
				</div>
				<div>
					<img src={img2} alt="" />
				</div>
				<div>
					<img src={img3} alt="" />
				</div>
				<div>
					<img src={img4} alt="" />
				</div>
			</OwlCarousel>
		</section>


		<section id='specialities'>
			<div className="container">
				<div className="row align-items-center text-center">
					<div className="col" onClick={() => {setFreeShipingModal(true)}}>
					<i className='bx bxs-truck' ></i>
						Free Shipping
					</div>
					<div className="col" onClick={() => {setReturnModal(true)}}>
					<i className='bx bx-analyse'></i>
						15-day Return Policy
					</div>
					<div className="col" onClick={() => {setStyledModal(true)}}>
					<i className='bx bxs-user-detail'></i>
						Styled More Than 10,000 Clients
					</div>
					{/* <div className="col" onClick={() => {setCountriesModal(true)}}>
						Shipping Happiness to 24 Countries
					</div> */}
					<div className="col" onClick={() => {setFittingModal(true)}}>
					<i className='bx bx-customize'></i>
						Custom Fitting
					</div>
				</div>
			</div>
		</section>

		<section id='products' className='mt-2'>
			<div className='container'>

				<div className="row">
					<div className="col-10 text-start">
						<h2 className='pb-4'>Bestsellers</h2>
					</div>
					<div className="col-2 text-end">
						<a href="">View More</a>
					</div>
				</div>

				{/* { !products ? <h4>Loading...</h4> : 
					<div className="row align-items-start">
						{products.slice(0,4).map((item, index) => (
							<div key={index} className="col-lg-3">
								<Link to={`/product/${item.id}`}>
									<img src={item.img} alt="" />
									<p className="lead m-0">{item.productDesc}</p>
									<p className="m-0">₹{item.price}</p>
								</Link>
							</div>
						))}
					</div>
				} */}
				<div className="row align-items-start">
					{ImgData.slice(0,4).map((item, index) => (
						<div key={index} className="col-lg-3">
							<Link to={`/productSample`}>
								<img src={item.img} alt="" />
								<p className="lead m-0">{item.productDesc}</p>
								<p className="m-0">₹{item.price}</p>
							</Link>
						</div>
					))}
				</div>
			</div>


			{/* <div className="row">
				<div className="col-md-8" >
					<div className="row">
						<div className="col-md-12" style={{height: '50%'}} >
							<img src={img1} alt="" />
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<img src={img5} alt="" />
						</div>
						<div className="col-lg-6">
							<img src={img5} alt="" />
						</div>
					</div>
				</div>
				<div className="col-md-4" style={{height: '100%'}}>
					<img src={img5} alt="" />
				</div>
			</div> */}

		</section>
			<Modal isOpen={freeShipingModal} onRequestClose={() => {setFreeShipingModal(false)}} style={customStyles}>
				<div className="row align-items-start">
					<div className="col-lg-10">
						<h2>Free Shipping</h2>
					</div>
					<div className="col-lg-2">
						<i className='bx bx-x bx-lg' onClick={() => {setFreeShipingModal(false)}}></i>
					</div>


				</div>
				<ul className='py-5'>
					<li>Free shippinh within India.</li>
					<li>For US - Free Shipping on orders above $99.</li>
				</ul>

				<div className="text-center">
					<Link to='/shipping' className='btn btn-primary'>Learn more about Free Shipping.</Link>
				</div>
			</Modal>

			<Modal isOpen={returnModal} onRequestClose={() => {setReturnModal(false)}} style={customStyles}>
				<div className="row align-items-start">
					<div className="col-lg-10">
						<h2>15-day Return Policy</h2>
					</div>
					<div className="col-lg-2">
						<i className='bx bx-x bx-lg' onClick={() => {setReturnModal(false)}}></i>
					</div>


				</div>
				<ul className='py-5'>
					<li><strong>For orders delivered within India - FREE Returns</strong></li>
					<li><strong>How can I claim my return?</strong>
						<ul>
							<li>We will accept returns within 15 days</li>
							<li>For returns/exchange requests kindly login to your account, click "My Returns/ Exchanges" and select the order number and place a request (check steps to claim at the bottom). Our Service Manager will revert back to you within 24 hours’ time.</li>
							<li>You can also return/exchange at our Kalki stores in Mumbai, India.</li>		
						</ul>
					</li>
					<li><strong>What can I return?</strong>
						<ul>
							<li>You may return any item purchased from Kalki Fashion that is unused, in new condition or any item that was damaged upon receipt.</li>
							<li>Customised styles, kids wear, jutties, blouses & accessories are non-returnable.</li>		
						</ul>
					</li>
				</ul>

				<div className="text-center">
					<Link to='/shipping' className='btn btn-primary'>Learn more about Free Shipping.</Link>
				</div>
			</Modal>

			<Modal isOpen={styledModal} onRequestClose={() => {setStyledModal(false)}} style={customStyles}>
				<div className="row align-items-start">
					<div className="col-lg-10">
						<h2>Styled More Than 10,000 Clients</h2>
					</div>
					<div className="col-lg-2">
						<i className='bx bx-x bx-lg' onClick={() => {setStyledModal(false)}}></i>
					</div>
				</div>
				
			</Modal>

			<Modal isOpen={countriesModal} onRequestClose={() => {setCountriesModal(false)}} style={customStyles}>
				<div className="row align-items-start">
					<div className="col-lg-10">
						<h2>Shipping Happiness to 24 Countries</h2>
					</div>
					<div className="col-lg-2">
						<i className='bx bx-x bx-lg' onClick={() => {setCountriesModal(false)}}></i>
					</div>
				</div>
			</Modal>

			<Modal isOpen={fittingModal} onRequestClose={() => {setFittingModal(false)}} style={customStyles}>
				<div className="row align-items-start">
					<div className="col-lg-10">
						<h2>Custom Fitting</h2>
					</div>
					<div className="col-lg-2">
						<i className='bx bx-x bx-lg' onClick={() => {setFittingModal(false)}}></i>
					</div>
				</div>
				<ul className='py-5'>
					<li><strong>Custom fitting</strong> is available for all customers, reach out to us on Chat or support.</li>
					<li><strong>Product customization</strong> is also available - For example, you need product A's Skirt and product B's Top. Or if you need a specific product in a specific Color</li>
				</ul>

				<div className="text-center">
					Reach out to us on chat <br /> or <br />
					<Link to='/shipping' className='btn btn-primary'>Book an e-appointment.</Link>
				</div>
			</Modal>

		<GoToTop/>

    </div>
  )
}

export default Home