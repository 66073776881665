import React from 'react';
import ReactDom, {hydrate} from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk';
import reducers from './reducers';

import App from './App';

const store = createStore(reducers, compose(applyMiddleware(thunk)))

const rootElement = document.getElementById("root");

    ReactDom.render(
        <Provider store={store}>
            <App />
        </Provider>,
        rootElement
    );
