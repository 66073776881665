import React from 'react'
import GoToTop from '../js/GoToTop'

const Payments = (props) => {
  return (
    <div className='payment'>
        <p className="lead">Select your payment method.</p>
        <div className="text-center my-3">
            <button className='btn mt-2' onClick={props.prevStep}>Back</button>
            <button className='btn btn-solid mt-2' onClick={props.nextStep}>Next</button>
        </div>
        <GoToTop />
    </div>
  )
}

export default Payments