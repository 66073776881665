import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCategory } from '../actions/products';
import GoToTop from './js/GoToTop';

const Category = () => {

	// const {category, isLoading} = useSelector((state) => state.category);
    const dispatch = useDispatch();
    const name = useParams();

    useEffect(() => {
        dispatch(fetchCategory(name))
    })

  return (
    <div className='category-page mt-4 px-3'>

        <div className="row align-items-start justify-content-center">
            <div className="col-lg-2 filters">

                <h5>Filters</h5><hr />

                <div className="price my-3">
                    <h6>Price</h6>

                    <select name="min" id="min">
                        <option value="min" disabled>Min</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        <option value="2500">2500</option>
                        <option value="5000">5000</option>
                    </select>

                    <select name="max" id="max">
                        <option value="max" disabled>Max</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        <option value="2500">2500</option>
                        <option value="5000+">5000+</option>
                    </select>

                </div>

                <div className="rating">
                    <h6>Customer Ratings</h6>
                    <input type="checkbox" name="4&above" id="4&above" />
                    <label htmlFor="4&above">4 & Above</label>
                </div>

            </div>

            <div className="col-lg-9">

            </div>
        </div>

        {/* { !category ? (<h4>Loading...</h4>) : (
            <div>

            </div>
         ) } */}
        <GoToTop />
    </div>
  )
}

export default Category