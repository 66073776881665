import { FETCH_PRODUCT, FETCH_ALL_PRODUCT, START_LOADING, END_LOADING, SIGNIN, FETCH_CATEGORY } from '../constants/actionTypes';

const productReducer = ( state = { isLoading: true, products: null}, action ) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case END_LOADING:
            return { ...state, isLoading: false };
        case FETCH_ALL_PRODUCT:
            return { ...state, products: action.data };
        case FETCH_PRODUCT:
            return { ...state, product: action.data };
        case FETCH_CATEGORY:
            // return { ...state, category: action.data };
            console.log(action)

        case SIGNIN: 
            localStorage.setItem('profile', JSON.stringify({...action?.data}));
            return { ...state, authData: action?.data };
        default:
            return state;
    }
}

export default productReducer;