import axios from 'axios';

const API = axios.create({ baseURL: 'http://localhost:8080' });
// const API = axios.create({ baseURL: 'https://fifth-chalice-380014.uc.r.appspot.com/' });

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    };

    return req;
});

export const fetchAllProducts = () => API.get('/product/getAllProduct');
export const fetchProductById = (id) => API.get(`/product/${id}`);
export const fetchCategory = (name) => API.get(`/product/category/${name}`);
export const fetchDesign = (no) => API.get(`/product/design/${no}`);
export const addWishList = (user, item) => API.post(`/product/${user}/addWishList`, item);
export const addCart = (user, item) => API.post(`/product/${user}/addCart`, item);
export const getWishListProduct = () => API.post(`/product/getWishListProduct`)
export const getCartProduct = () => API.post(`/product/getCartProduct`)

export const fetchSearchProduct = (query) => API.get(`/product/searchProduct?keyword=${query}`);
export const fetchFilterProduct = () => API.get('/product/filterProduct');
export const signIn = (formData) => API.post('/users/signin', formData);