import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signIn } from '../actions/products';
import GoToTop from './js/GoToTop';


const initialState = { phone: '', encodedPassword: '' }

const Login = () => {

    const user = JSON.parse(localStorage.getItem('profile'))
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialState)


    useEffect(() => {
        if(user) {
            navigate('/dashboard')
        }
    }, [])


    const formSubmit = (e) => {
        e.preventDefault();
        dispatch(signIn(formData, navigate));
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


  return (
    <div className='container mt-4 d-flex justify-content-center'>
        <div className='login-modal'>
            <h2 className='text-center pt-5'>Login with Tokree</h2>

            
            <form onSubmit={formSubmit}>

                <input type='number' name="phone" className='form-control' placeholder='Phone Number' onChange={handleChange} />

                <input type='password' name="encodedPassword" className='form-control' placeholder='Password' onChange={handleChange} />

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="checkBox" defaultChecked />
                    <label className="form-check-label" htmlFor="checkBox">
                        Remember Me
                    </label>
                </div>

                <div className="text-center mt-3">
                    <button type='submit' className='btn btn-primary btn-solid'>Login</button>
                    {/* <p>*Your email address is safe with us.</p> */}
                    {/* Or */}
                </div>

            </form>

            <div className="text-center my-3">
                <i className='bx bxl-facebook-square bx-md' style={{color: '#c79d6f'}} />
                <i className='bx bxl-google bx-md' style={{color: '#c79d6f'}} />
                <p>Don't have an account? <Link to='/signup'  style={{color: '#c79d6f'}}>Sign up here</Link></p>
            </div>
        </div>
        <GoToTop />
    </div>
  )
}

export default Login