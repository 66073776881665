import React from "react";
import Modal from 'react-modal';
import Main from "./components/Main";

Modal.setAppElement('#root');
function App() {
	return (
		<div className="App">
			<Main />
		</div>
	);
}

export default App;
