import { START_LOADING, END_LOADING, FETCH_ALL_PRODUCT, FETCH_PRODUCT, SEARCH_PRODUCT, SIGNIN, FETCH_CATEGORY } from '../constants/actionTypes';
import * as api from '../api';


export const fetchSearchProduct = (query) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const {data} = await api.fetchSearchProduct(query);

        dispatch({ type: SEARCH_PRODUCT, data: data })

        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error)
    }
}

export const fetchAllProduct = () => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const {data} = await api.fetchAllProducts();
        dispatch({ type: FETCH_ALL_PRODUCT, data: data })

        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error)
    }
}

export const fetchProductById = (id) => async (dispatch) => {
    try {

        dispatch({ type: START_LOADING });

        const { data } = await api.fetchProductById(id.id);

        console.log(data)

        dispatch({type: FETCH_PRODUCT, data: data})


        dispatch({ type: END_LOADING });
        
    } catch (error) {
        console.log(error)
    }
}

export const fetchCategory = (name) => async (dispatch) => {
    try {

        dispatch({ type: START_LOADING });

        const { data } = await api.fetchCategory(name.name);

        dispatch({type: FETCH_CATEGORY, data})

        dispatch({ type: END_LOADING });
        
    } catch (error) {
        console.log(error)
    }
}

export const fetchDesign = (no) => async (dispatch) => {
    try {

        dispatch({ type: START_LOADING });

        const { data } = await api.fetchDesign(no.no);

        dispatch({type: FETCH_CATEGORY, data})

        dispatch({ type: END_LOADING });
        
    } catch (error) {
        console.log(error)
    }
}

export const signIn = (formData, navigate) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})

        const { data } = await api.signIn(formData);

        navigate('/')

        dispatch({type: SIGNIN, data: data})

        dispatch({type: END_LOADING})

    } catch (error) {
        console.log(error)
    }
}

export const addWishList = (user, item) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})

        const {data} = await api.addWishList(user, item);

        dispatch({type: END_LOADING})

    } catch (error) {
        
    }
}
export const addCart = (user, item) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})

        const {data} = await api.addCart(user, item);

        dispatch({type: END_LOADING})

    } catch (error) {
        console.log(error)
    }
}

export const getWishListProduct = () => async (dispatch) => {
    try {

        dispatch({type: START_LOADING})

        const {data} = await api.getWishListProduct();

        dispatch({type: END_LOADING})
        
    } catch (error) {
        console.log(error)
    }
}

export const getCartProduct = () => async (dispatch) => {
    try {

        dispatch({type: START_LOADING})

        const {data} = await api.getCartProduct();

        dispatch({type: END_LOADING})
        
    } catch (error) {
        console.log(error)
    }
}