export const NavlinkData = [
    {
        title: 'Saree',
        link: 'saree',
        data: [
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            },
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            },
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            }
        ]
    },
    {
        title: 'Lehengas',
        link: 'lehengas',
        data: [
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            },
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            },
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            }
        ]
    },
    {
        title: 'Kurtis',
        link: 'kurtis',
        data: [
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            },
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            },
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            }
        ]
    },
    {
        title: 'Accessories',
        link: 'accessories',
        data: [
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            },
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            },
            {
                title: 'Style',
                list: ['sharara suits', 'anarkali suit', 'palazoo suit', 'designer salwar kameez', 'kurta set with dupatta', 'jump suit']
            }
        ]
    }
]