import React from 'react';
import { Link } from 'react-router-dom';
import GoToTop from '../js/GoToTop';

const Thanks = () => {
  return (
    <div className='thanks text-center'>
        <h4 className='my-4'>Thank you for your order. Your package will be delivered soon.</h4>
        <Link className='btn btn-solid'>Continue Shopping</Link>
        <GoToTop />
    </div>
  )
}

export default Thanks