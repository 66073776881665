import React from 'react'
import GoToTop from '../js/GoToTop'

const Address = (props) => {
  return (
    <div>
        <div className="address">
            <p className="lead">Select your delivery address.</p>
            <input type="checkbox" name="address1" id="address1" />
            <label htmlFor="address1" className='mx-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, eum?</label><br />
            <input type="checkbox" name="address2" id="address2" />
            <label htmlFor="address2" className='mx-2'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium sequi accusamus nesciunt quod reiciendis cum?</label><br />
        </div>
        <div className="text-center my-3">
            <button className='btn mt-2' onClick={props.prevStep}>Back</button>
            <button className='btn btn-solid mt-2' onClick={props.nextStep}>Proceed to payment.</button>
        </div>
        <GoToTop />
    </div>
  )
}

export default Address