import React, { useState } from 'react';
import {Link, NavLink} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavlinkData } from './data/NavlinkData';
import img1 from '../media/tokree_logo.png';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { signIn } from '../actions/products';



const Navbar = () => {


    const [sidebar, setSidebar] = useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [ subMenu, setSubMenu ] = useState({ open: null, close: null })
    const dispatch = useDispatch();

    const [options, setoptions] = useState({
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    })

    const [loginModal, setLoginModal] = useState(false);
    const [classes1, setClasses1] = useState('active')
    const [classes2, setClasses2] = useState('');

    const showSubmenu = (title) => {
        if(subMenu.open === title) {
            setSubMenu({open: null})
        } else {
            setSubMenu({open: title, close: 'all'})
        }
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '15px 20px 50px 20px',
          borderRadius: '20px',
          minHeight: '20vh',
		  width: '25vw',
        },
    };


return (
    <div className='sticky-top main-head'>

    <header className="header container d-flex justify-content-between">
        <div className={sidebar ? 'div1 cross' : 'div1'} onClick={showSidebar}>
            <div className='sub1'></div>
            <div className='sub2'></div>
            <div className='sub3'></div>
        </div>

        <Link className="logoContainer" to="/">
            {/* <h1 className='m-0 logo-text'>Tokree</h1> */}
            <img src={img1} alt=""/>
        </Link>

        <nav>
            <ul className="nav justify-content-center">
            {NavlinkData.map((item, index) => (
                <li key={index}>{item.title}
                    <div className="subMenu">
                        <div id="womenEthic" className="submenuList">
                        {item.data.map((items, index) => (
                            <ul key={index}>
                                {items.list.map((listItems, index) => (
                                    <li key={index}>
                                        <Link to={`/${item.link}/${listItems.replaceAll(' ', '-')}`}>{listItems}</Link>
                                    </li>
                                ))}
                            </ul>
                        ))}
                        </div>
                    </div>
                </li>
            ))}
            </ul>
        </nav>

        <div className={sidebar ? 'sidebar active' : 'sidebar'}>
            <div className="container">
                    <ul className="justify-content-end">
                    {NavlinkData.map((item, index) => (
                        <li key={index}>
                            <h3 style={{cursor: 'pointer'}} onClick={() => {showSubmenu(item.title)}}>{item.title}</h3>
                            {(subMenu.open === item.title) ? <div className="subMenu">
                                <div id="womenEthic" className="submenuList">
                                {item.data.map((items, index) => (
                                    <ul key={index}>
                                        {items.list.map((listItems, index) => (
                                            <li key={index}>
                                                <Link to={`/${item.link}/${listItems.replaceAll(' ', '-')}`}>{listItems}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                ))}
                                </div>
                            </div> : null}
                        </li>
                    ))}
                </ul>
            </div>
            <div className='sidebar-bg' onClick={showSidebar}></div>
        </div>

        <div className="headerRight">
            {/* <div className="searchInputContainer">
                <div className="searchIcon">
                    <i className='bx bx-search-alt-2'></i>
                </div>
                <form action="" id="inputForm">
                    <input type="text" placeholder="Search for Saree, Kurti" className="inputSearch" />
                </form>
                <div className="inputCloseSearch">
                    <i className="fa-solid fa-xmark" id="closeSearch"></i>
                </div>

                <div className="searchRecentModal">
                    <h3>Recent Searches</h3>
                    <div className="listofRecent">

                    </div>
                </div>
            </div>
            <div className="downloadContainer">
                <div className="mobileIcon">
                    <i className='bx bx-mobile-alt' ></i>
                </div>
                <p>Download App</p>

                <div className="downloadHoverBtnContainer">
                    <h6>Download From</h6>

                    <a href="" className="downloadBtn">
                        <img src="https://images.meesho.com/images/pow/playstore-icon-big.webp" />
                    </a>
                    <a href="" className="downloadBtn">
                        <img src="https://images.meesho.com/images/pow/appstore-icon-big.webp" />
                    </a>
                </div>
            </div> */}

            <div className="profileAndCart">
                <div className="searchcont">
                    <div className="profileIcon">
                        <i className='bx bx-search-alt-2' ></i>
                    </div>
                    <p>Search</p>

                    <div className="searchHoverContainer">
                        <div className="searchInputContainer">
                            <div className="searchIcon">
                                <i className='bx bx-search-alt-2'></i>
                            </div>
                            <form action="" id="inputForm">
                                <input type="text" placeholder="Search for Saree, Kurti" className="inputSearch" />
                            </form>
                            <div className="inputCloseSearch">
                                <i className="fa-solid fa-xmark" id="closeSearch"></i>
                            </div>

                            <div className="searchRecentModal">
                                <h3>Recent Searches</h3>
                                <div className="listofRecent">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Link className="profileContainer" to='/login'>
                    <div className="profileIcon">
                        <i className='bx bx-user' ></i>
                    </div>
                    <p>Profile</p>
                </Link>

                <Link className="CartContainer" to='/bag'>
                    <div className="CartIcon">
                        <i className='bx bx-shopping-bag' ></i>
                    </div>
                    <p>Bag</p>
                </Link>
            </div>
        </div>

    </header>

    {/* <nav className='containe'>
        <ul className="nav justify-content-end">
            <li>Women Ethnic
                <div className="subMenu">
                    <div id="womenEthic" className="submenuList">
                        <p>djfkh</p>
                    </div>
                </div>
            </li>
        {NavlinkData.map((item, index) => (
            <li key={index}>{item.title}
                <div className="subMenu">
                    <div id="womenEthic" className="submenuList">
                    {item.data.map((items, index) => (
                        <ul key={index}>
                            {items.list.map((listItems, index) => (
                                <li key={index}>
                                    <Link to={`/${item.link}/${listItems.replaceAll(' ', '-')}`}>{listItems}</Link>
                                </li>
                            ))}
                        </ul>
                    ))}
                    </div>
                </div>
            </li>
        ))}
        </ul>

    </nav> */}


    {/* <nav className="navbar navbar-expand-lg top-bar">
        <div className="container">
            <Link className="navbar-brand" to="/"><img src={img1} alt="" height='60' /></Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            
            <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                <form className="navbar-nav mb-2 mb-lg-0" role="search">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>
                </form>
            </div>

            <ul className="navbar-nav ml-auto mb-2 mb-lg-0 d-flex">
                <li className="nav-item">
                    <NavLink className={({ isActive }) => isActive ? "nav-link" : "nav-link"} to='/'><i className='bx bx-cart'></i></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className={({ isActive }) => isActive ? "nav-link" : "nav-link"} onClick={() => {setLoginModal(true)}}><i className='bx bx-user'></i></NavLink>
                </li>
            </ul>
        </div>
    </nav> */}

    {/* <nav className="navbar navbar-expand-lg text-center middle-bar">
        <div className="container">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                <ul className="navbar-nav mb-2 mb-lg-0">
                    {NavlinkData.map((item, index) => (
                        <li key={index} className="nav-item text-uppercase">
                            <NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} to={`/shop/${item.link}`}>{item.title}</NavLink>
                            <div className="middle-nav-lists">
                                {item.data.map((items, index) => (
                                    <ul key={index}>
                                        <h6>{items.title}</h6>
                                        <hr />
                                        {items.list.map((listItems, index) => (
                                            <li key={index}>
                                                <Link to={`/${item.link}/${listItems.replaceAll(' ', '-')}`}>{listItems}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                ))}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </nav> */}


    {/* <Modal isOpen={loginModal} onRequestClose={() => {setLoginModal(false)}} style={customStyles} >
        <div className='login-modal'>
            <i className='bx bx-x bx-md' style={{float: 'right'}} onClick={() => {setLoginModal(false)}} />
            <h2 className='text-center pt-5'>Login with Tokree</h2>

            <ul>
                <li className={classes1} onClick={() => {setClasses1('active'); setClasses2('')}}>Via Mail</li>
                <li className={classes2} onClick={() => {setClasses1(''); setClasses2('active')}}>Via Mobile</li>
            </ul>
            
            <form onSubmit={formSubmit}>

                <input type="email" className='form-control' placeholder='Email' />

                <input type="password" className='form-control' placeholder='Password' />

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="checkBox" defaultChecked />
                    <label className="form-check-label" htmlFor="checkBox">
                        Remember Me
                    </label>
                </div>

                <div className="text-center">
                    <button type='submit' className='btn btn-primary btn-solid'>Login</button>
                    <p>*Your email address is safe with us.</p>
                    Or
                </div>

            </form>

            <div className="text-center my-3">
                <i className='bx bxl-facebook-square bx-md' style={{color: '#c79d6f'}} />
                <i className='bx bxl-google bx-md' style={{color: '#c79d6f'}} />
                <p>Don't have an account? <Link to='/'  style={{color: '#c79d6f'}}>Sign up here</Link></p>
            </div>



        </div>
    </Modal> */}
        
    </div>
    )
}

export default Navbar