import React, {useState} from 'react';
import Address from './Address';
import Items from './Items';
import Payments from './Payments';
import Thanks from './Thanks';

const Cart = () => {
	const [step, setStep] = useState(1);

	const nextStep = () => {
		setStep(step+1)
	}

	const prevStep = () => {
		setStep(step-1)
	}


	switch(step){
		case 1:
			return(
				<div className="cart-page mt-4 container">
					<h1 className="text-center">Shopping Bag</h1>
					<Items nextStep={nextStep} prevStep={prevStep} />
				</div>
			)
		case 2:
			return(
				<div className="cart-page mt-4 container">
					<h1 className="text-center">Shopping Bag</h1>
					<Address nextStep={nextStep} prevStep={prevStep} />
				</div>
			)
		case 3:
			return(
				<div className="cart-page mt-4 container">
					<h1 className="text-center">Shopping Bag</h1>
					<Payments nextStep={nextStep} prevStep={prevStep} />
				</div>
			)
		case 4:
			return(
				<div className="cart-page mt-4 container">
					<h1 className="text-center">Shopping Bag</h1>
					<Thanks nextStep={nextStep} prevStep={prevStep} />
				</div>
			)
	}
}

export default Cart