import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import img1 from '../../media/img1.webp';
import { fetchProductById } from '../../actions/products';
import GoToTop from '../js/GoToTop'

const ProductSample = () => {

	const {product, isLoading} = useSelector((state) => state.product);
    const id = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(fetchProductById(id));
    }, [])

    
    return (
        <div className='product-page mt-4 container'>
            <div id='product' className='my-2'>
    
                <div className="row align-items-start">
                    <div className="col-lg-2">
                        <img src={img1} alt="" width={120} className='mb-2' />
                        <img src={img1} alt="" width={120} className='mb-2' />
                        <img src={img1} alt="" width={120} className='mb-2' />
                    </div>
                    <div className="col-lg-5">
                        <img src={img1} alt="" />
                    </div>
                    <div className="col-lg-5">
                        <h4>Red Banarasi Silk Saree with Unstitched Blouse</h4>
                        <div className="star">
                            <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                            <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                            <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                            <i className='bx bxs-star' style={{color: '#c79d6f'}} />
                            <i className='bx bxs-star' style={{color: '#c79d6f'}} /> 
                            34 reviews
                        </div>
                        <br /><br />
                        <h3 className='disPrice'>₹8,999 <span>10% OFF</span></h3>
                        <h4 className='main-price'>₹9,999</h4>
    
                        <div className="size-chart">
                            <button className='btn'>Size chart</button>
                        </div>
    
                        <div className="sizes mt-4">
                            <span>Select Size</span>
                            <div>
                                <ul>
                                    {/* {product.sizeAvailableList.map((item, index) => (
                                        <li key={index}>US {item}</li>
                                    ))} */}
                                    <li>US 10</li>
                                    <li>US 20</li>
                                    <li>US 30</li>
                                    <li>US 40</li>
                                    <li>US 50</li>
                                </ul>
                            </div>
                        </div>

                        <div className="btn"><i className='bx bx-heart'></i> Wishlist</div>
                        <div className="btn btn-solid"><i className='bx bx-shopping-bag' ></i> Add to Bag</div>
                        <h5 className='mt-4 proddesc'>Product Description</h5>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur culpa odit, delectus ducimus error fuga soluta officia, unde doloremque, inventore pariatur deserunt incidunt ea molestiae reiciendis vel eos at reprehenderit?</p>    
                    </div>
                </div>
            </div>
            <GoToTop/>
        </div>
    )
}

export default ProductSample